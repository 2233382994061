<template>
	<MobileAppWizardStep
		v-model="application"
		:steps="steps"
		:step-name="stepName"
		:start-with-last="startWithLast"
		@previous="$emit('previous')"
		@next="$emit('next')"
		@unvalidated="$emit('unvalidated')"
		@validated="$emit('validated')"
	/>
</template>

<script>
export default {
	name: 'MobileAppStepGooglePlayStore',
	components: {
		MobileAppWizardStep: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppWizardStep')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		},
		startWithLast: {
			required: false,
			type: Boolean,
			default: false
		},
		stepName: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			steps: [
				{
					title: this.$t('mobileapp.creationSteps.followNotionArticle'),
					href: () =>
						'https://www.notion.so/welyb/Etape-2-Cr-ation-de-la-fiche-de-l-application-mobile-sur-le-PlayStore-Google-12f72a54007580c181ebfc779f0118a7?pvs=4',
					valuesToCopy: () => [
						{ name: this.$t('mobileapp.creationSteps.android.title'), value: this.application.title },
						{ name: this.$t('mobileapp.creationSteps.android.marketing_url'), value: this.application.marketing_url },
						{ name: this.$t('mobileapp.creationSteps.android.promotional_text'), value: this.application.promotional_text },
						{ name: this.$t('mobileapp.creationSteps.android.description'), value: this.application.description }
					]
				}
			]
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
